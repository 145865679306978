import React, { useEffect, useRef, useState } from "react"
import CaseyRainLoop from "../../videos/casey-ceremony/casey-rain-loop.mp4"
import CaseyRainIntro from "../../videos/casey-ceremony/casey-rain-intro.mp4"

type Props = {
  onIntroFinished: () => void
}

const CaseyRainVideo: React.FC<Props> = ({ onIntroFinished }) => {
  const caseyRainIntroRef = useRef<HTMLVideoElement>(null)
  const caseyRainLoopRef = useRef<HTMLVideoElement>(null)

  const [introPlaying, setIntroPlaying] = useState(true)

  useEffect(() => {
    if (caseyRainLoopRef.current && !introPlaying) {
      caseyRainLoopRef.current.play()
      onIntroFinished()
    }
  }, [introPlaying, caseyRainLoopRef.current])

  return (
    <>
      <video
        ref={caseyRainIntroRef}
        src={CaseyRainIntro}
        autoPlay
        playsInline
        controls={false}
        onEnded={() => {
          setIntroPlaying(false)
          caseyRainLoopRef?.current?.play()
          onIntroFinished()
        }}
        className={`h-[100vh] w-[100vw] object-cover ${
          introPlaying ? "" : "hidden"
        }`}
      />
      <video
        ref={caseyRainLoopRef}
        src={CaseyRainLoop}
        playsInline
        loop
        controls={false}
        className={`h-[100vh] w-[100vw] object-cover ${
          introPlaying ? "hidden" : ""
        }`}
      />
    </>
  )
}

export default CaseyRainVideo
