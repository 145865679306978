import React, { useEffect, useRef, useState } from "react"
import LoadingIntro from "../../videos/casey-ceremony/loading-intro.mp4"
import OgVideosWrapper, { OG_VIDEOS_STATES } from "./OgVideosWrapper"
import CaseyRainVideo from "./CaseyRainVideo"

type Props = {
  ogVideosState: number
  setOgVideosState: (state: number) => void
  eventStarted: boolean
  introFinished: () => void
  refetchEventData: () => void
  refetchBuyCaseyData: () => void
}

const BackgroundVideos: React.FC<Props> = ({
  eventStarted,
  ogVideosState,
  setOgVideosState,
  introFinished,
  refetchEventData,
  refetchBuyCaseyData,
}) => {
  const introRef = useRef<HTMLVideoElement>(null)
  const caseyRainRef = useRef<HTMLVideoElement>(null)

  const [introPlaying, setIntroPlaying] = useState(false)
  const [caseyRainPlaying, setCaseyRainPlaying] = useState(false)

  useEffect(() => {
    refetchEventData()
  }, [])

  useEffect(() => {
    if (introRef.current && introPlaying) {
      introRef.current.play()
    }
  }, [introPlaying, introRef.current])

  useEffect(() => {
    if (caseyRainRef.current && caseyRainPlaying) {
      caseyRainRef.current.play()
    }
  }, [caseyRainPlaying, caseyRainRef.current])

  useEffect(() => {
    if (ogVideosState === OG_VIDEOS_STATES.allShotOut) {
      setCaseyRainPlaying(true)
    }
  }, [ogVideosState])

  return (
    <section className="relative flex h-full w-full flex-col items-center justify-center">
      <OgVideosWrapper
        shotPermitted={eventStarted}
        state={ogVideosState}
        setState={setOgVideosState}
      />
      {introPlaying && (
        <video
          ref={introRef}
          src={LoadingIntro}
          playsInline
          controls={false}
          className="max-h-[100vh] object-contain"
          onEnded={() => {
            setIntroPlaying(false)
            setOgVideosState(OG_VIDEOS_STATES.loading)
            introFinished()
          }}
        />
      )}
      {caseyRainPlaying && (
        <CaseyRainVideo onIntroFinished={() => refetchBuyCaseyData()} />
      )}
      {!introPlaying && ogVideosState === OG_VIDEOS_STATES.hidden && (
        <button
          className="absolute top-1/2 left-1/2 h-fit w-fit -translate-x-1/2 -translate-y-1/2 rounded-full bg-green py-2 px-4 text-center font-gravity-wide text-[12px] uppercase leading-normal text-black md:text-[14px]"
          onClick={() => {
            setIntroPlaying(true)
            setOgVideosState(OG_VIDEOS_STATES.loadingInitiated)
          }}
        >
          Initiate Og Drop
        </button>
      )}
    </section>
  )
}

export default BackgroundVideos
