import React, { useEffect, useState } from "react"
import CrossIcon from "../../images/svg/cross.svg"
import TitleLogo from "../../images/svg/casey-ceremony/titleLogo.svg"
import { graphql, useStaticQuery } from "gatsby"
import EventCountdown from "../EventCountdown"

const saleFinishedButtonClasses =
  "pointer-events-auto block text-md mx-auto h-fit w-fit rounded-full bg-green py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out lg:min-w-[220px]"

type Props = {
  mintUrl: string
  refetchEventData: () => void
}

const PDP: React.FC<Props> = ({ mintUrl, refetchEventData }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)

  const handleToggleDetails = () => {
    setShowDetails(!showDetails)
    setShowDisclaimer(false)
  }
  const handleToggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer)
    setShowDetails(false)
  }

  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            caseyCeremony {
              eventStartsAt
              eventEndsAt
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    refetchEventData()
  }, [])

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-end gap-4 pb-4 text-center font-gravity-wide text-[12px] uppercase sm:w-auto lg:gap-12 lg:text-[14px]">
      <div className="flex flex-1 flex-col items-center justify-between gap-4">
        <h1 className="pointer-events-none mt-[66px] mb-4 flex w-full flex-col items-center justify-center text-center font-gravity-wide text-[16px] font-black uppercase leading-[1.2] md:text-[40px] lg:text-[2.65vw]">
          <span>ORE ORE ORE</span>
          <span className="flex items-center">
            <TitleLogo className="h-[50px] w-[50px] md:h-[80px] md:w-[80px]" />
            OG DROP
          </span>
        </h1>
      </div>
      <div className="pointer-events-auto relative flex w-full flex-col justify-center gap-4">
        <div className="absolute bottom-[160px] left-1/2 flex w-full max-w-[90%] -translate-x-1/2 justify-center text-[10px] font-normal normal-case md:max-w-[900px] md:text-[14px]">
          {showDetails && (
            <div className="pointer-events-auto relative flex w-full flex-col gap-6 rounded-[10px] bg-white/50 p-4 pr-8 text-left font-sans backdrop-blur-lg transition-opacity duration-150 ease-in-out">
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDetails}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[14px]">
                Details
              </h3>
              <div className="flex flex-col gap-2 font-sans text-[8px] uppercase leading-tight lg:text-[12px]">
                <div>
                  The “OG 101” drop marks the 5th instalment of 1 of 1 artworks,
                  by ORE ORE ORE, brought to you by the OREMOB.
                </div>
                <div>
                  Each piece is hand drawn, and has its name attached to the day
                  it was originally created. With the expansion of the MOB,
                  supply of OG’s is highly limited, comparative to demand - as
                  such, each drop is WL only (or acquired via specified
                  guidelines).
                </div>
                <div>
                  These works form the cornerstone of the OREMOB, and are prized
                  by collectors. Whilst being driven by the art, holders also
                  see value in owning OG’s, via, WL allocations, access to
                  exclusive fashion drops, and future use case in the OREMOB
                  Encasing game.
                </div>
              </div>
            </div>
          )}
          {showDisclaimer && (
            <div className="relative flex flex-col gap-[16px] rounded-[10px] bg-white/50 p-4 pr-8 text-left font-sans backdrop-blur-lg transition-opacity duration-150 ease-in-out">
              <button
                className="absolute right-4 top-4 appearance-none border-0 bg-transparent p-0"
                onClick={handleToggleDisclaimer}
              >
                <CrossIcon className="h-4 w-4 text-white" />
              </button>
              <h3 className="font-gravity-wide text-[1.2em] font-black uppercase leading-none tracking-wide">
                Disclaimer:
              </h3>
              <ol className="list-disc pl-4 uppercase leading-tight">
                <li>SALE IS WL ONLY (NO PUBLIC MINT) AND WALLET GATED</li>
                <li>
                  OG ASSETS ARE LIMITED TO 101 TOTAL AND ARE DIGITAL IN NATURE
                </li>
                <li>NFT PRICE 499 ADA</li>
                <li>BUYER TO RECEIVE 1X “OG” OREMOB NFT ASSET </li>
                <li>LIMITED TO 1 NFT PER BUYER, UNLESS SPECIFIED OTHERWISE</li>
                <li>SALE OPEN FOR 48HRS</li>
                <li>UNMINTED NFTS WILL BE RAFFLED IN LIGHTNING RAFFLES </li>
              </ol>
              <h3 className="font-gravity-wide text-[1.2em] font-black uppercase leading-none tracking-wide">
                Drop Contents:
              </h3>
              <ol className="list-disc pl-4 uppercase leading-tight">
                <li>
                  <span>
                    Digital/NFTs:Total of 101 OREMOB “OG” NFTs, made up of the
                    below:
                  </span>
                  <ol className="list-none pl-2 uppercase leading-tight">
                    <li>- Common - 48/101</li>
                    <li>- Uncommon - 27/101</li>
                    <li>- Rare - 25/101</li>
                    <li>- Legendary - 1/101</li>
                  </ol>
                </li>
                <li>
                  <span>Physical Items: </span>
                  <ol className="list-none pl-2 uppercase leading-tight">
                    <li>- N/A</li>
                  </ol>
                </li>
              </ol>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-center gap-4 lg:gap-6">
          <button
            className={`flex w-[160px] items-center justify-center justify-self-end rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-green hover:text-black focus:text-black lg:w-[220px] ${
              showDetails ? "bg-green" : "bg-white"
            }`}
            onClick={handleToggleDetails}
          >
            Details
          </button>
          <button
            className={`flex w-[160px] items-center justify-center rounded-full py-2 px-4 uppercase leading-normal text-black transition-colors duration-200 ease-in-out hover:bg-green hover:text-black focus:text-black lg:w-[220px] ${
              showDisclaimer ? "bg-green" : "bg-white"
            }`}
            onClick={handleToggleDisclaimer}
          >
            Disclaimer
          </button>
        </div>
        <div className="font-gravity-wide">
          <div className="flex flex-col items-center gap-2 pb-2 leading-none">
            <span className="text-[0.9em]">Price:</span>
            <span className="text-[1.3em]">499 ADA</span>
          </div>
          <EventCountdown
            cta={() => mintUrl && window.open(mintUrl)}
            eventEndedText="MINT ENDED"
            eventEndsAt={site.siteMetadata.caseyCeremony.eventEndsAt}
            eventStartsAt={site.siteMetadata.caseyCeremony.eventStartsAt}
            eventEndedStyles="text-black bg-white"
            buttonClasses={saleFinishedButtonClasses}
            onEventEnded={() => refetchEventData()}
            onEventStarted={() => refetchEventData()}
          >
            <span>MINT NOW</span>
          </EventCountdown>
        </div>
      </div>
    </div>
  )
}

export default PDP
