import React, { useEffect, useRef, useState } from "react"
import { OG_VIDEOS_STATES, OgVideo } from "./OgVideosWrapper"
import { createPortal } from "react-dom"

const POSITIONS_Y = {
  top: "-translate-y-[500%]",
  bottom: "translate-y-[500%]",
}

const FAST_PLAYBACK_SPEED_TIME = 800

type Props = {
  state: number
  videoInfo: OgVideo
  shouldShow: boolean
  shotPermitted: boolean
  onLoadFinished: () => void
  onShoot: () => void
}

const OgVideoWrapper: React.FC<Props> = ({
  onLoadFinished,
  onShoot,
  shotPermitted,
  shouldShow,
  videoInfo,
  state,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const [translateY, setTranslateY] = useState(POSITIONS_Y.bottom)
  const [showDetails, setShowDetails] = useState(false)

  const setPlayBack = (playback: number) => {
    if (videoRef?.current) videoRef.current.playbackRate = playback
  }

  useEffect(() => {
    if (!videoRef?.current) return
    if (!videoRef.current.paused) return
    if (!shouldShow) return

    const timer = setTimeout(() => {
      videoRef?.current?.play()
      setPlayBack(8)
      setTranslateY(videoInfo.translateY)
      setTimeout(() => {
        setPlayBack(videoInfo.playbackSpeed)
        onLoadFinished()
      }, FAST_PLAYBACK_SPEED_TIME)
    }, videoInfo.delay)

    return () => {
      clearTimeout(timer)
    }
  }, [videoInfo.delay, videoInfo.translateY, videoRef.current, shouldShow])

  return (
    <div className="relative flex h-full flex-1">
      <div
        className={`absolute w-full ${
          showDetails ? "z-[1]" : ""
        } cursor-pointer transition-all duration-1000 ease-out will-change-transform ${
          videoInfo.translateX
        } top-1/2 left-1/2 ${translateY}`}
      >
        <video
          autoPlay
          loop
          muted
          ref={videoRef}
          className={`relative object-contain ${videoInfo.scale}`}
          onClick={() => {
            if (!shotPermitted || state !== OG_VIDEOS_STATES.loadingFinished)
              return
            setTranslateY(POSITIONS_Y.top)
            setShowDetails(false)
            onShoot()
          }}
          onMouseEnter={() => setShowDetails(true)}
          onMouseLeave={() => setShowDetails(false)}
        >
          <source src={videoInfo.url} type="video/mp4" />
        </video>
        {showDetails && createPortal(
          <div
            className={`fixed z-[10] ${videoInfo.detailsStyle} pointer-events-none flex h-fit w-max max-w-[70vw] flex-col gap-2 rounded-[10px] bg-white/50 p-4 pr-4 text-left font-sans backdrop-blur-md transition-opacity duration-500 ease-out sm:max-w-[100vw]`}
          >
            <h3 className="font-gravity-wide text-[10px] font-black uppercase leading-tight md:text-[12px]">
              {videoInfo.videoDetails.title}
            </h3>
            <div className="flex max-w-[250px] flex-col gap-2 font-sans text-[8px] font-black uppercase leading-tight md:text-[10px]">
              <div className="flex justify-between gap-4">
                <h5>First Appearence:</h5>
                <span>{videoInfo.videoDetails.firstAppearence}</span>
              </div>
              <div className="flex justify-between gap-4">
                <h5>Created:</h5>
                <span>{videoInfo.videoDetails.dateCreated}</span>
              </div>
              <div className="flex flex-col justify-between gap-1">
                <h5>Origin:</h5>
                <ol className="list-disc pl-6 leading-tight">
                  {videoInfo.videoDetails.origin.map((text, index) => (
                    <li key={index}>{text}</li>
                  ))}
                </ol>
              </div>
              {videoInfo.videoDetails.utility && (
                <div className="flex flex-col justify-between gap-1">
                  <h5>Utility:</h5>
                  <ul className="list-disc pl-6 leading-tight">
                    {videoInfo.videoDetails.utility.map((utility, index) => (
                      <li key={index}>
                        {utility.title}
                        {utility.infos && (
                          <ul className="list-item pt-2 leading-tight">
                            {utility.infos.map((text, index) => (
                              <li key={index}>- {text}</li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex justify-between gap-6">
                <h5>Total Cases:</h5>
                <span>{videoInfo.videoDetails.totalCases}</span>
              </div>
              <div className="flex justify-between gap-6">
                <h5>Cases Released within this drop:</h5>
                <span>{videoInfo.videoDetails.casesWithinDrop}</span>
              </div>
            </div>
          </div>
        , document.getElementById('main-container') ?? document.body)}
      </div>
    </div>
  )
}

export default OgVideoWrapper
