import React, { useEffect, useRef } from "react"
import DefaultAudio from "../../videos/casey-ceremony/casey-ceremony.mp3"
import RainActivatedAudio from "../../videos/casey-ceremony/casey-ceremony-rain-activated.mp3"

type Props = {
  rainActivated: boolean
}

const CaseyAudio: React.FC<Props> = ({ rainActivated }) => {
  const defaultAudioRef = useRef<HTMLAudioElement>(null)
  const rainActivatedAudio = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    let volumeInterval: NodeJS.Timeout
    if (
      rainActivated &&
      defaultAudioRef.current &&
      rainActivatedAudio.current
    ) {
      rainActivatedAudio.current.volume = 0
      defaultAudioRef.current.volume = 1
      rainActivatedAudio.current?.play()

      volumeInterval = setInterval(() => {
        if (
          rainActivated &&
          defaultAudioRef.current &&
          rainActivatedAudio.current
        ) {
          defaultAudioRef.current.volume = Math.max(
            defaultAudioRef.current.volume - 0.01,
            0
          )
          rainActivatedAudio.current.volume = Math.min(
            rainActivatedAudio.current.volume + 0.01,
            1
          )
        }
      }, 10)

      setTimeout(() => {
        clearInterval(volumeInterval)
        defaultAudioRef.current?.pause()
      }, 1000)
    }

    return () => {
      clearInterval(volumeInterval)
    }
  }, [rainActivated, defaultAudioRef.current, rainActivatedAudio.current])

  return (
    <div className="hidden">
      <audio ref={defaultAudioRef} src={DefaultAudio} autoPlay loop />
      <audio ref={rainActivatedAudio} src={RainActivatedAudio} loop />
    </div>
  )
}

export default CaseyAudio
