import React from "react"

type Props = {
  buyUrl: string
  soldOut: boolean
}

const BuyCaseySecret: React.FC<Props> = ({ buyUrl, soldOut }) => {
  return (
    <div className="font-gravity-wide">
      <div className="flex flex-col items-center gap-2 pb-2 uppercase leading-none">
        <span className="text-[0.9em]">Price:</span>
        <span className="text-[1.3em]">50 ADA</span>
      </div>
      <button
        className={`w-[160px] rounded-full bg-green py-2 px-4 text-[12px] uppercase leading-normal text-black transition-colors duration-200 ease-in-out focus:text-black md:text-[14px] lg:w-[220px] ${
          soldOut
            ? "pointer-events-none cursor-default bg-white text-black"
            : ""
        }`}
        onClick={() => {
          if (soldOut || !buyUrl) return
          window.open(buyUrl)
        }}
      >
        {soldOut ? "sold out" : "mint casey"}
      </button>
    </div>
  )
}

export default BuyCaseySecret
