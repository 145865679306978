import React, { useEffect, useState } from "react"
import Ogfy from "../../videos/casey-ceremony/ogfy.mp4"
import Headhunter from "../../videos/casey-ceremony/headhunter.mp4"
import n190722 from "../../videos/casey-ceremony/190722.mp4"
import Demon from "../../videos/casey-ceremony/demon.mp4"
import DragonEmblem from "../../videos/casey-ceremony/dragon-emblem.mp4"
import OgVideoWrapper from "./OgVideoWrapper"

export type OgVideo = {
  url: string
  delay: number
  scale: string
  translateY: string
  translateX: string
  detailsStyle: string
  playbackSpeed: number
  videoDetails: VideoDetails
}

type VideoDetails = {
  title: string
  firstAppearence: string
  dateCreated: string
  origin: string[]
  utility?: { title: string; infos?: string[] }[]
  totalCases: number
  casesWithinDrop: number
}

const videos: OgVideo[] = [
  {
    url: Ogfy,
    delay: 0,
    scale: `scale-[1.2]`,
    translateY: "-translate-y-[55%]",
    translateX: "-translate-x-[50%]",
    detailsStyle: "left-[20%] top-[28%]",
    playbackSpeed: 1,
    videoDetails: {
      title: "Oremob ogfy casing",
      firstAppearence: "OG 101 Drop",
      dateCreated: "November 2023",
      origin: [
        'A casing made for the "ogfy" project, which turns pieces of the oremob 10k into og pieces.',
      ],
      utility: [
        {
          title:
            "Holders of the casing are eligible for a one-time claim of the ogfy pfp casing including the dedicated ceremony accolade.",
        },
      ],
      totalCases: 6,
      casesWithinDrop: 6,
    },
  },
  {
    url: Headhunter,
    delay: 900,
    scale: `scale-[0.7]`,
    translateY: "-translate-y-[100%]",
    translateX: "-translate-x-[60%]",
    detailsStyle: "left-[35%] top-[12%]",
    playbackSpeed: 1.1,
    videoDetails: {
      title: "Headhunter Casing",
      firstAppearence: "OG 111 Drop",
      dateCreated: "November 2021",
      origin: [
        "A casing inspired by the cathedrails of prague and the holy relics showcased within them.",
      ],
      totalCases: 11,
      casesWithinDrop: 1,
    },
  },
  {
    url: n190722,
    delay: 1100,
    scale: `scale-[1]`,
    translateY: "-translate-y-[30%]",
    translateX: "-translate-x-[50%]",
    detailsStyle: "right-[59%] top-[46%]",
    playbackSpeed: 0.9,
    videoDetails: {
      title: "Original Casing",
      firstAppearence: "OG 30 Drop",
      dateCreated: "April 2021",
      origin: [
        "The first og casing. Designed and created by jugora and ore ore ore. Starting off the og series, the very first nfts of ore ore ore were released in this casing",
      ],
      totalCases: 295,
      casesWithinDrop: 75,
    },
  },
  {
    url: Demon,
    delay: 1300,
    scale: `scale-[0.7]`,
    translateY: "-translate-y-[90%]",
    translateX: "-translate-x-[50%]",
    detailsStyle: "right-[37%] top-[16%]",
    playbackSpeed: 1.2,
    videoDetails: {
      title: "demon casing",
      firstAppearence: "OG Demon Drop",
      dateCreated: "December 2021",
      origin: [
        "A casing made for the most exclusive og drop to date. Inspired by japanese folklore and tales about demons and evil spirits, this drop told the story of 11 demons and their legacies",
      ],
      totalCases: 24,
      casesWithinDrop: 3,
    },
  },
  {
    url: DragonEmblem,
    delay: 300,
    scale: `scale-[1.1]`,
    translateY: "-translate-y-[30%]",
    translateX: "-translate-x-[50%]",
    detailsStyle: "right-[20%] top-[40%]",
    playbackSpeed: 1.05,
    videoDetails: {
      title: "oremob x ffs casing",
      firstAppearence: "OG 101 Drop",
      dateCreated: "october 2023",
      origin: [
        "A casing made in celebration for the first oremob sneaker: seed one-bone dragon",
      ],
      utility: [
        {
          title: "holders of this casing are eligible for the following:",
          infos: [
            "bone dragon - seed one (PHY)",
            "oremob x ffs balaclava (PHY)",
            "seed one - digital twin (NFT)",
            "balaclava - digital twin (NFT)",
            "Dragon charm ring (NFT)",
            "bone dragon casey (NFT)",
          ],
        },
      ],
      totalCases: 5,
      casesWithinDrop: 5,
    },
  },
]

export const OG_VIDEOS_STATES = {
  hidden: 0, // videos are not visible
  loadingInitiated: 1, // audio starts
  loading: 2, // videos are rising from the screen on high speed
  loadingFinished: 3, // videos are just playing the normal playback speed
  allShotOut: 4, // all videos have been shot out of the screen
}

type Props = {
  shotPermitted: boolean
  state: number
  setState: (state: number) => void
}

const OgVideosWrapper: React.FC<Props> = ({
  shotPermitted,
  state,
  setState,
}) => {
  const [loadedVideos, setLoadedVideos] = useState(0)
  const [videosShot, setVideosShot] = useState(0)
  const [showWrapper, setShowWrapper] = useState(true)

  useEffect(() => {
    if (loadedVideos === videos.length) {
      setState(OG_VIDEOS_STATES.loadingFinished)
    }
  }, [loadedVideos])

  useEffect(() => {
    if (videosShot === videos.length) {
      setState(OG_VIDEOS_STATES.allShotOut)
      setTimeout(() => setShowWrapper(false), 500)
    }
  }, [videosShot])

  return (
    <>
      {showWrapper && (
        <section className="absolute flex h-full w-full overflow-hidden">
          {videos.map((video, index) => (
            <OgVideoWrapper
              key={index}
              state={state}
              videoInfo={video}
              shouldShow={state >= OG_VIDEOS_STATES.loading}
              onLoadFinished={() => setLoadedVideos(s => s + 1)}
              onShoot={() => setVideosShot(s => s + 1)}
              shotPermitted={shotPermitted}
            />
          ))}
        </section>
      )}
    </>
  )
}

export default OgVideosWrapper
