/* eslint-disable no-empty */
import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PDP from "../components/casey-ceremony/pdp"
import BackgroundVideos from "../components/casey-ceremony/BackgroundVideos"
import { graphql, useStaticQuery } from "gatsby"
import { OG_VIDEOS_STATES } from "../components/casey-ceremony/OgVideosWrapper"
import BuyCaseySecret from "../components/casey-ceremony/BuyCaseySecret"
import CaseyAudio from "../components/casey-ceremony/CaseyAudio"

type EventResponse = {
  mintUrl: string
}
const fetchEventData = async (baseUrl: string) => {
  const res = await fetch(`${baseUrl}/events/casey-ceremony`, {
    method: "GET",
  }).then(res => {
    if (res.ok) return res.json()
    return Promise.reject(res)
  })
  return res as EventResponse
}

type BuyCaseyResponse = {
  buyUrl: string
  soldOut: boolean
}
const fetchBuyCaseyData = async (baseUrl: string) => {
  const res = await fetch(`${baseUrl}/events/casey-ceremony/buy-casey`, {
    method: "GET",
  }).then(res => {
    if (res.ok) return res.json()
    return Promise.reject(res)
  })
  return res as BuyCaseyResponse
}

const BoneDragon: React.FC = () => {
  const [hasIntroFinished, setIntroFinished] = useState(false)
  const [mintUrl, setMintUrl] = useState("")
  const [buyUrl, setBuyUrl] = useState("")
  const [soldOut, setSoldOut] = useState(false)
  const [eventStarted, setEventStarted] = useState(false)
  const [ogVideosState, setOgVideosState] = useState(OG_VIDEOS_STATES.hidden)

  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            backendUrl
          }
        }
      }
    `
  )

  return (
    <Layout bgColor="black">
      <SEO title="Casey Ceremony" />

      <div className="relative flex h-screen flex-col items-center justify-center" id="main-container">
        <BackgroundVideos
          ogVideosState={ogVideosState}
          setOgVideosState={setOgVideosState}
          eventStarted={eventStarted}
          introFinished={() => setIntroFinished(true)}
          refetchEventData={async () => {
            try {
              const eventData = await fetchEventData(
                site.siteMetadata.backendUrl
              )
              setMintUrl(eventData.mintUrl)
              setEventStarted(true)
            } catch (e) {}
          }}
          refetchBuyCaseyData={async () => {
            try {
              const buyCaseyData = await fetchBuyCaseyData(
                site.siteMetadata.backendUrl
              )
              setBuyUrl(buyCaseyData.buyUrl)
              setSoldOut(buyCaseyData.soldOut)
            } catch (e) {}
          }}
        />
        <div
          className={`pointer-events-none absolute inset-0 z-[1] flex h-full w-full flex-col items-center justify-center opacity-0 transition-all duration-500 ease-in-out ${
            !hasIntroFinished ? "invisible" : "opacity-100"
          }`}
        >
          <PDP
            mintUrl={mintUrl}
            refetchEventData={async () => {
              try {
                const eventData = await fetchEventData(
                  site.siteMetadata.backendUrl
                )
                setMintUrl(eventData.mintUrl)
                setEventStarted(true)
              } catch (e) {}
            }}
          />
        </div>
        <div
          className={`opacity-0 transition-all ${
            buyUrl || soldOut ? "opacity-100" : ""
          } pointer-events-auto absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 duration-500 ease-in-out sm:bottom-[15px] sm:right-[50px] sm:translate-x-0 sm:translate-y-0`}
        >
          {(buyUrl || soldOut) && (
            <BuyCaseySecret buyUrl={buyUrl} soldOut={soldOut} />
          )}
        </div>
        {ogVideosState > OG_VIDEOS_STATES.hidden && (
          <CaseyAudio
            rainActivated={ogVideosState === OG_VIDEOS_STATES.allShotOut}
          />
        )}
      </div>
    </Layout>
  )
}

export default BoneDragon
